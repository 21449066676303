<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>导入旧系统会员</span>
            </div>

            <!-- 设置会员等级-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToMemberLevelSetting"
                            class="font1"
                            :disabled="!hasEditMemberLevelPrivilege"
                        >
                            设置会员等级
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForEditMemberLevel"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可设置一个机构组下通用的会员等级
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToMemberLevelSetting"
                            :disabled="!hasEditMemberLevelPrivilege"
                            >①添加会员等级</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            默认1级10折会员(无任何折扣)-编辑-添加-输入新的会员等级名称、积分(消费1元积1积分，达到规定积分自动升到下一会员等级)、折扣-点击保存
                        </span>
                    </el-col>
                </el-row>
            </el-card>

            <!--初始化会员-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToMemberImport"
                            class="font1"
                            :disabled="!hasCreateMemberImportPrivilege"
                            >初始化会员</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateMemberImport"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可为店面初始化会员</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToMemberImport"
                            :disabled="!hasCreateMemberImportPrivilege"
                            >①下载会员导入模板</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称-下载模板-在EXCEL中填写会员姓名、手机号、会员等级默认1级(选填)、
                            余额(选填)- 保存EXCEL后点击导入-选择修改后的EXCEL文件-导入成功后勾选此数据点击审核
                            -审核通过后此会员方可在收银机被查到
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const memberLevelPrivilegeFlag = 'menu.member.level';
const editMemberLevelPrivilegeFlag = `${memberLevelPrivilegeFlag}.edit`;

const memberImportPrivilegeFlag = 'menu.member.import';
const createMemberImportPrivilegeFlag = `${memberImportPrivilegeFlag}.create`;

export default {
    name: 'DeptImportMemberGuide',
    data() {
        return {
            rolesForEditMemberLevel: [],
            rolesForCreateMemberImport: [],
        };
    },
    created() {
        //查询能够"修改会员等级基础数据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(editMemberLevelPrivilegeFlag).then((rst) => {
            this.rolesForEditMemberLevel = rst;
        });
        //查询能够"创建会员导入"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createMemberImportPrivilegeFlag).then((rst) => {
            this.rolesForCreateMemberImport = rst;
        });
    },
    computed: {
        roleNamesForEditMemberLevel() {
            return this.rolesForEditMemberLevel.map((e) => e.name).join('，');
        },
        roleNamesForCreateMemberImport() {
            return this.rolesForCreateMemberImport.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToMemberLevelSetting() {
            Util.nameJump(this, memberLevelPrivilegeFlag);
        },
        jumpToMemberImport() {
            Util.nameJump(this, memberImportPrivilegeFlag);
        },
        hasEditMemberLevelPrivilege() {
            return this.hasPrivilege(editMemberLevelPrivilegeFlag);
        },
        hasCreateMemberImportPrivilege() {
            return this.hasPrivilege(createMemberImportPrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
